import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import api from "../../api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Breadcrumb from "../breadCrumb/Breadcrumb";

import ShopFilter from "./ShopFilter";
import MultiCategoryShopFilter from "./MultiCategoryShopFilter"; // New Component for Multiple IDs

const Shop = ({
  apiEndpoint = "/filters/get_sub_categories_list",
  pageTitle = "",
}) => {
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [storeData, setStoreData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const color_id = location.state?.color_id;
  const categoryId = location.state?.categoryId;
  const subCategoryId = location.state?.subCategoryId;

  const fetchStoreData = async () => {
    setLoading(true);
    try {
      const api_token = localStorage.getItem("apiToken");
      const response = await api.get(apiEndpoint, {
        params: {
          api_token: api_token,
          segment_id: segment_id || "",
          gender_id: gender_id || 2,
          category_id: category_id || "",
          page: 0,
        },
      });

      if (response.status === 401) {
        logout();
        navigate("/home");
        return;
      }

      if (response.status === 200) {
        setStoreData(response.data.data);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStoreData();
  }, [apiEndpoint, segment_id, gender_id, category_id]);

  const isCategoryIdArray = Array.isArray(categoryId) && categoryId.length > 0;
  const isSubCategoryIdArray =
    Array.isArray(subCategoryId) && subCategoryId.length > 0;
  const handleProductClick = (productId) => {
    // dispatch(setScrollPosition(window.scrollY));
    navigate(`/product/${productId}`);
  };

  return (
    <div className="main-content not-home shop-section-data">
      {isCategoryIdArray || isSubCategoryIdArray ? (
        <MultiCategoryShopFilter
          categoryIds={isCategoryIdArray ? categoryId : [category_id]}
          subCategoryIds={
            isSubCategoryIdArray ? subCategoryId : [sub_category_id]
          }
          SubCategoryList_data={storeData}
          isLoading={loading}
        />
      ) : (
        <>
          <Breadcrumb />
          <ShopFilter
            SubCategoryList_data={storeData}
            isLoading={loading}
            onProductClick={handleProductClick} // Pass click handler
          />
        </>
      )}
    </div>
  );
};

export default Shop;
