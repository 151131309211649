import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SortSelect from "../multiSelect/SortSelect";
import MultiSelectSize from "../multiSelect/multiSelectSize";
import MultiSelectColor from "../multiSelect/multiSelectColor";
import MultiInputPrice from "../multiSelect/MultiInputPrice";
import ClearAllFilterData from "../multiSelect/ClearAllFilterData";
import UtilsContext from "../../context/UtilsContext";
import "./Filter.css";

const ShopTabFilter = ({
  sortOptions,
  sizeOptions,
  colorOptions,
  onSortChange,
  onSizeChange,
  onPriceChange,
  onColorChange,
  onClearFilters,
  onPriceClearFilter,
  onSizeClearFilter,
  onColorClearFilter,
  sortOption,
  colorOption,
  sizeOption,
  totalCount,
  showAdditionalFilters,
  loading,
}) => {
  const [selectedSort, setSelectedSort] = useState(sortOption || "");
  const [selectedSize, setSelectedSize] = useState(sizeOption || []);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [selectedColors, setSelectedColors] = useState(colorOption || []);
  const { priceOption } = useContext(UtilsContext);
  const [selectedPriceRange, setSelectedPriceRange] = useState(priceOption);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isListVisible, setIsListVisible] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 768);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const toggleMobileFilter = () => {
    setIsMobileFilterVisible((prev) => !prev);
    setIsListVisible(!isListVisible);
  };
  const toggleMobileFilter1 = (e) => {
    setIsMobileFilterVisible(false);
    setIsListVisible(false);
  };
  return (
    <div className="tab-filter">
      {isMobile && (
        <div className="filter-buttons-container">
          {!isLoading && (
            //   <Skeleton width={55} />
            // ) : (
            <div className="total-item">{totalCount} items</div>
          )}
          <div className="filter-actions-row">
            <div
              className="desktop-clear clear ilters__buttons-item gc n-6-of-12"
              style={{ display: "none" }}
            >
              <ClearAllFilterData
                onClearFilters={onClearFilters}
                setSelectedColors={setSelectedColors}
                setSelectedSizes={setSelectedSize}
                setSelectedPrice={setSelectedPrice}
                setSelectedPriceRange={setSelectedPriceRange}
                setSelectedSort={setSelectedSort}
              />
            </div>
            <div className="sort-container">
              {/* {isLoading ? (
                <Skeleton width={155} />
              ) : ( */}
              <div onClick={toggleMobileFilter1}>
                <SortSelect
                  onChange={(value) => {
                    setSelectedSort(value);
                    onSortChange(value);
                  }}
                  data={sortOptions}
                  selectedValue={selectedSort}
                />
              </div>
              {/* )} */}

              <button
                onClick={toggleMobileFilter}
                className="filter-button-btn"
              >
                Filters{" "}
                <span
                  className={`icon icon--arrow-${
                    isListVisible ? "up" : "down"
                  } icon--sm`}
                >
                  <svg
                    viewBox="0 0 16 16"
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    height={18}
                    width={18}
                  >
                    <path
                      d={
                        isListVisible
                          ? "M8 5.5a.751.751 0 0 1 .55.24l3.25 3.5a.75.75 0 1 1-1.1 1.02L8 7.352 5.3 10.26a.75.75 0 1 1-1.1-1.021l3.25-3.5a.751.751 0 0 1 .55-.24z"
                          : "M8 10.5a.751.751 0 0 1-.55-.24L4.2 6.76A.75.75 0 1 1 5.3 5.74L8 8.648l2.7-2.908a.75.75 0 1 1 1.1 1.021l-3.25 3.5a.751.751 0 0 1-.55.24z"
                      }
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="filter-option">
        {(isMobileFilterVisible || !isMobile) && showAdditionalFilters && (
          <>
            {!isMobile && (
              <div className="sort-container">
                {/* {isLoading ? (
                  <Skeleton width={155} />
                ) : ( */}
                <SortSelect
                  onChange={(value) => {
                    setSelectedSort(value);
                    onSortChange(value);
                  }}
                  data={sortOptions}
                  selectedValue={selectedSort}
                />
                {/* )} */}
              </div>
            )}

            {/* {isLoading ? (
              <Skeleton width={155} />
            ) : ( */}
            <MultiInputPrice
              selectedPriceValue={selectedPriceRange}
              onChange={(value) => {
                setSelectedPriceRange(value);
                onPriceChange(value);
              }}
              onPriceClearFilter={onPriceClearFilter}
            />
            {/* )}
            {isLoading ? (
              <Skeleton width={155} />
            ) : ( */}
            <MultiSelectSize
              onChange={(value) => {
                setSelectedSize(value);
                onSizeChange(value);
              }}
              data={sizeOptions}
              selectedSizes={selectedSize}
              onSizeClearFilter={onSizeClearFilter}
            />
            {/* )}
            {isLoading ? (
              <Skeleton width={155} />
            ) : ( */}
            <MultiSelectColor
              onChange={(value) => {
                setSelectedColors(value);
                onColorChange(value);
              }}
              data={colorOptions}
              selectedColors={selectedColors}
              onColorClearFilter={onColorClearFilter}
            />
            {/* )} */}
            {isMobileFilterVisible && (
              <>
                <div className="button-action-shop g filters__buttons">
                  <div className="ilters__buttons-item gc n-6-of-12">
                    <button
                      onClick={toggleMobileFilter}
                      className="filter-button-btn-done  btn "
                    >
                      Done{" "}
                    </button>
                  </div>
                  <div className="desktop-clear clear ilters__buttons-item gc n-6-of-12">
                    <ClearAllFilterData
                      onClearFilters={onClearFilters}
                      setSelectedColors={setSelectedColors}
                      setSelectedSizes={setSelectedSize}
                      setSelectedPrice={setSelectedPrice}
                      setSelectedPriceRange={setSelectedPriceRange}
                      setSelectedSort={setSelectedSort}
                    />
                  </div>
                </div>
              </>
            )}
            {!isMobile && (
              <div className="desktop-clear clear">
                <ClearAllFilterData
                  onClearFilters={onClearFilters}
                  setSelectedColors={setSelectedColors}
                  setSelectedSizes={setSelectedSize}
                  setSelectedPrice={setSelectedPrice}
                  setSelectedPriceRange={setSelectedPriceRange}
                  setSelectedSort={setSelectedSort}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ShopTabFilter.propTypes = {
  sortOptions: PropTypes.array.isRequired,
  sizeOptions: PropTypes.array.isRequired,
  colorOptions: PropTypes.array.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onColorChange: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onPriceClearFilter: PropTypes.func.isRequired,
  onSizeClearFilter: PropTypes.func.isRequired,
  onColorClearFilter: PropTypes.func.isRequired,
  sortOption: PropTypes.string.isRequired,
  showAdditionalFilters: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ShopTabFilter;
